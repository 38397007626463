import { CreditRouter, AdminRouter } from "../routes";

export const APPS = [
    {
        subdomain: 'credit',
        app: CreditRouter,
        main: true
    }, {
        subdomain: 'admin',
        app: AdminRouter,
        main: false
    },
]