import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiService from "../services/apiService";

export const fetchCustomer = createAsyncThunk("get/customers",
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.fetchSingleCustomer(payload.idNumber, payload.apiKey);
            if (response.data.status === true) {
                return { status: true, message: response.data.data }
            } else {
                return { status: false, message: 'No Customer Info found. Try again later' }
            }
        } catch (error) {
            console.error(`api service error: ${error}`)
            return { status: false, message: error.response.status === 403 ? 'Invalid API Key. Please try again later' : 'Something went wrong, Try after sometime.' }
        }
    }
);

const initialState = { isLoading: false }

const fetchCustomerSlice = createSlice({
    name: "auth",
    initialState,
    extraReducers: {
        [fetchCustomer.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [fetchCustomer.rejected]: (state, action) => {
            state.isLoading = false;
        },
    },
});

const { reducer } = fetchCustomerSlice;
export default reducer;