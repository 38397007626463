import axios from "axios";
const API_URL = process.env.REACT_APP_API_BASE_URL
const AMT_API_URL = process.env.REACT_APP_AMT_API_BASE_URL

const register = (payload) => {
    return axios.post(`${API_URL}credit-score`, payload);
};
const fetchSingleCustomer = (idNumber, apiKey) => {
    return axios.get(`${AMT_API_URL}customers/${idNumber}`, {
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        }
    })
}

const updateAmtCredit = (id, payload, apiKey) => {
    return axios.put(`${AMT_API_URL}customers/credit/${id}`, payload, {
        headers: {
            'x-api-key': apiKey,
            'Content-Type': 'application/json'
        }
    })
}

const scoreService = {
    register,
    fetchSingleCustomer,
    updateAmtCredit
}

export default scoreService;