import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "./message";
import { setStatusCode } from "./statusCode";
import apiService from "../services/apiService";

export const loginRequest = createAsyncThunk("auth/login",
    async (payload, thunkAPI) => {
        try {
            const response = await apiService.login(payload);
            thunkAPI.dispatch(setMessage(response.data.message));
            thunkAPI.dispatch(setStatusCode(response.status))
            return response;
        } catch (error) {
            thunkAPI.dispatch(setMessage(error.response.data.detail.toString()));
            thunkAPI.dispatch(setStatusCode(error.response.status))
            return thunkAPI.rejectWithValue();
        }
    }
);

const initialState = { isLoading: false }

const loginSlice = createSlice({
    name: "login",
    initialState,
    extraReducers: {
        [loginRequest.fulfilled]: (state, action) => {
            state.isLoading = false;
        },
        [loginRequest.rejected]: (state, action) => {
            state.isLoading = false;
        },
    },
});

const { reducer } = loginSlice;
export default reducer;