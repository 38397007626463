import { createTheme } from '@mui/material/styles';


export const THEME = createTheme({
    palette: {
        primary: {
            main: '#46bcaa',
        },
        secondary: {
            main: '#ffcf52',
        },
        dark: {
            main: '#1f2128'
        },
        warning: {
            main: '#ffcf52'
        },
        danger: {
            main: '#f35421'
        },
        brand: {
            main: '#ffcd02'
        },
        background: {
            light: '#F3F5FF'
        }
    },
    typography: {
        "fontFamily": 'Work Sans',
        "fontSize": 14
    },
    button: {
        "fontFamily": 'Work Sans',
        "fontSize": 14
    },
    MuiButton: {
        styleOverrides: {
            root: {
                "fontFamily": 'Work Sans',
                "fontSize": 14,
            }
        }
    },
    overrides: {
        MuiButton: {
            root: {
                "fontFamily": 'Work Sans',
                "fontSize": 44,
            }
        },
        MuiFormControlLabel: {
            label: {
                "fontFamily": 'Work Sans',
                "fontSize": "10px"
            }
        },
        MuiInputLabel: {
            root: {
                color: 'black',
                fontSize: 13,
            },
        },
    },
    shadows: Array(25).fill('none')
});

