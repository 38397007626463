import React, { useEffect, useState } from 'react';
import "./CreditDecision.css"
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Typography } from '@mui/material';
import CreditModal from '../../components/Modal/Modal';
import LOGO from '../../assets/sunlogo.png';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { creditDecision } from '../../helpers/options';
import { useDispatch } from "react-redux";
import { connect } from 'react-redux';
import { updateCredit } from '../../slices/updateCredit';
import XCreditScore from '../../components/Common/XCreditScore';
import XProgress from '../../components/Common/XProgress';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useNavigationType } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { ArrowBack } from '@mui/icons-material';
const theme = createTheme();

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function CreditDecision() {
    const [credit, setCredit] = useState("")
    const [state, setState] = React.useState({ open: false, message: "", severity: "" });
    const [progress, setProgress] = useState(false)
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const navigationType = useNavigationType()
    const { open, message, severity } = state;

    useEffect(() => {
        if (location.state === null) {
            navigate('/civ/surveys', { replace: true })
        }
        if (navigationType === 'POP') {
            navigate('/', { replace: true })
            window.location.reload()
        }
        clearTextView()
    }, [location.state, navigate, navigationType])

    const handleClose = () => {
        setState({ ...state, open: false });
    };

    const createPost = () => {
        window.scrollTo(0, 0)
        setProgress(true)
        try {
            dispatch(updateCredit({ idNumber: location.state.idNumber, apiKey: process.env.REACT_APP_API_KEY, "credit": credit === "TRUE" ? true : false })).unwrap().then((response) => {
                setState({ open: true, message: response.message, severity: response.status === true ? 'success' : 'error' });
                clearTextView()
                navigateToScorePage()
            }).catch((e) => {
                setState({ open: true, message: e, severity: 'error' });
                navigateToScorePage()
            });
        } catch (e) {
            console.error(e)
            setState({ open: true, message: e, severity: 'error' });
            navigateToScorePage()
        }
    }

    const navigateToScorePage = () => {
        setTimeout(() => {
            setProgress(false)
        }, 2000)
    }

    const clearTextView = () => {
        setCredit("")
    }

    return (
        <>
            <ThemeProvider theme={theme}>
                <div className="appheader1">
                    <Container component="main" maxWidth="md" minWidth="md">
                        <CssBaseline />
                        <Box sx={{
                            marginTop: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: 4,
                            borderRadius: 2,
                            backgroundColor: '#F3F2F1',
                            boxShadow: '0 0px 1px 0 #F3F2F1',
                        }}>
                            <Grid container fullWidth style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Grid item xs={10}>
                                    <img height={100} src={LOGO} alt="sunculture logo" />
                                </Grid>
                                <Grid item xs={2}>
                                    <Button
                                        sx={{ fontFamily: 'Work Sans', marginRight: '10px' }}
                                        color='secondary'
                                        size='small'
                                        variant="outlined"
                                        onClick={() => navigate('/civ/surveys', { replace: true })}>
                                        <ArrowBack />{t('button.exit')}
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container fullWidth noValidate mt={2} sm={{ mt: 0, backgroundColor: 'red', textAlign: 'center' }}>
                                <Typography variant="h6" className='checkList'>{t("credit.decision.title")}</Typography>
                                <>
                                    {progress && <XProgress message={t('please.wait.update.credit')} />}
                                    <XCreditScore message={location.state !== null ? location.state.score : ""} statuscode={location.state !== null ? location.state.statusCode : ""} />

                                    <TextField
                                        style={{ marginTop: "25px" }}
                                        margin="normal"
                                        required
                                        fullWidth
                                        size='small'
                                        disabled
                                        id="apiKey1"
                                        label={t('input.enter.api.key')}
                                        name="apiKey1"
                                        value={process.env.REACT_APP_API_KEY}
                                        autoFocus
                                    />
                                </>

                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth component="fieldset">
                                            <FormLabel component="legend" className='label2'>{t('reference.number')}</FormLabel>
                                            <TextField
                                                disabled
                                                margin="small"
                                                required
                                                size='small'
                                                fullWidth
                                                value={location.state !== null ? location.state.idNumber : ""}
                                                name="_name"
                                                type="text"
                                                id="_name"
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                <Grid container mt={1} spacing={2}>
                                    <Grid item xs={12} md={12}>
                                        <FormControl fullWidth component="fieldset">
                                            <FormLabel component="legend" className='label2'>{t('label.form.credit.decision')}</FormLabel>
                                            <TextField
                                                fullWidth
                                                id="outlined-select-currency"
                                                select
                                                size='small'
                                                margin='small'
                                                value={credit}
                                                onChange={(e) => setCredit(e.target.value)}>
                                                {creditDecision.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </FormControl>
                                    </Grid>
                                </Grid>

                                <Button
                                    onClick={createPost}
                                    fullWidth
                                    variant="contained"
                                    disabled={
                                        credit === "" || location.state.idNumber === "" ? true : false
                                    }
                                    sx={{ mt: 3, mb: 2 }}>
                                    {t('button.submit.update.amt')}
                                </Button>
                            </Grid>
                        </Box>
                    </Container>
                </div>
            </ThemeProvider>
            <CreditModal />

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={open}
                message={message}
                autoHideDuration={6000}
                key="topcenter">
                <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>{message}</Alert>
            </Snackbar>
        </>
    );
}

const mapStateToProps = function (state) {
    return {
        loading: state.loading,
        creditScoreState: state.creditScoreState
    }
}

export default connect(mapStateToProps)(CreditDecision);
