import React, { Suspense, useEffect } from 'react'
import "./App.css"
import { ThemeProvider } from '@mui/material/styles'
import { THEME } from '../helpers/theme'
import Spinner from '../components/Spinner/Spinner'
import { getApp } from '../utils/urlhelper'

function App() {
	useEffect(() => {
		if (typeof localStorage.getItem('lang') === 'undefined' || localStorage.getItem('lang') === null) {
			localStorage.setItem('lang', 'en')
		}
	}, [])
	const CurrentApp = getApp()

	return (
		<ThemeProvider theme={THEME}>
			<Suspense fallback={<Spinner />}>
				<CurrentApp />
			</Suspense>
		</ThemeProvider>
	)
}

export default App
