import { APPS } from './constants'


export const getApp = () => {
    const subdomain = getSubdomain(window.location.hostname)

    const main = APPS.find((app) => app.main)

    if (subdomain === "") {
        localStorage.setItem("accountType", "Credit")
        return main.app
    }

    const app = APPS.find((app) => subdomain === app.subdomain)

    if (!app) {
        localStorage.setItem("accountType", "Credit")
        return main.app
    } else {
        localStorage.setItem("accountType", capitalizeFirstLetter(subdomain))
        return app.app
    }
}


const getSubdomain = (location) => {

    const locationParts = location.split(".")

    if (locationParts.includes('admin')) {
        return 'admin'
    } else if (locationParts.includes('credit')) {
        return 'credit'
    } else {
        return 'credit'
    }
}

const capitalizeFirstLetter = (str) => {
    const [firstLetter, ...rest] = str

    return firstLetter.toUpperCase() + rest.join('')
}