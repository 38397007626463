import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import ProtectedRoute from '../routes/protectedRoute'
import CreditDecision from '../pages/CreditDecision/CreditDecision';
const CreditScore = lazy(() => import('../pages/CreditScore/CreditScore'))
const PageNotFound = lazy(() => import('../pages/PageNotFound/PageNotFound'))
const LoginPage = lazy(() => import('../pages/Login/LoginPage'))
const SurveyPage = lazy(() => import('../pages/Surveys/SurveyPage'))
const Layout = lazy(() => import('../layout/Layout'))


export const CreditRouter = () => {
    return (
        <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route exact path='/v1/ke/creditscore' element={<CreditScore />} />
            <Route exact path="/v1/ke/creditdecision" element={<CreditDecision />} />
            <Route path="/civ/surveys" element={<ProtectedRoute> <SurveyPage /></ProtectedRoute>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

export const AdminRouter = () => {
    return (
        <Routes>
            <Route exact path="/" element={<LoginPage />} />
            <Route path="*" element={<ProtectedRoute> <Layout /></ProtectedRoute>} />
        </Routes>
    )
}