import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({
    redirectPath = '/',
    children,
}) => {
    const isAuthenticated = localStorage.getItem("isLoggedIn");
    if (!isAuthenticated) {
        return <Navigate to={redirectPath} replace />;
    }

    return children;
};

export default ProtectedRoute